import type { FC } from 'react';
import React, { useState } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import AkChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import AkChevronRightIcon from '@atlaskit/icon/utility/chevron-right';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceBlogCollapsibleSectionHeader = styled.button({
	display: 'flex',
	height: '36px',
	cursor: 'pointer',
	alignItems: 'center',
	position: 'relative',
	font: token('font.heading.large'),
	padding: '0px',
	borderRadius: '3px',
	border: 'none',
	backgroundColor: 'transparent',
	width: '100%',

	'&:hover, &:focus': {
		backgroundColor: token('color.background.neutral'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceSidebarCollapsibleSectionWrapper = styled.div<{ isOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: ({ isOpen }) => (isOpen ? '18px' : '0'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FadeInContainer = styled.div<{ isOpen: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isOpen }) => (isOpen ? '100%' : '0%'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: ({ isOpen }) => (isOpen ? 'visible' : 'hidden'),
	transition: 'opacity 0.5s ease',
});

type SpaceBlogsCollapsibleSectionProps = {
	children: React.ReactNode;
	year: string;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildrenWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '15px',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingBottom: '15px',
});

export const SpaceBlogsCollapsibleSection: FC<SpaceBlogsCollapsibleSectionProps> = ({
	children,
	year,
}) => {
	const [isOpen, setIsOpen] = useState(true);

	return (
		<SpaceSidebarCollapsibleSectionWrapper
			data-testid="space-blogs-collapsible-section"
			isOpen={isOpen}
		>
			<SpaceBlogCollapsibleSectionHeader
				onClick={() => setIsOpen(!isOpen)}
				aria-expanded={isOpen}
				aria-controls="blogs-year-collapsible-content"
			>
				<div id="collapsible-header-chevron">
					{isOpen ? (
						<AkChevronDownIcon label="collapse-chevron" spacing="spacious" />
					) : (
						<AkChevronRightIcon label="expand-chevron" spacing="spacious" />
					)}
				</div>
				{year}
			</SpaceBlogCollapsibleSectionHeader>

			<FadeInContainer
				id="blogs-year-collapsible-content"
				role="region"
				aria-labelledby={year}
				isOpen={isOpen}
			>
				<ChildrenWrapper>{isOpen && children}</ChildrenWrapper>
			</FadeInContainer>
		</SpaceSidebarCollapsibleSectionWrapper>
	);
};
