import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { PageCardAppearanceToggle } from '@confluence/page-card';

import { SpaceBlogsContext } from './SpaceBlogsContext';

// eslint-disable-next-line @atlaskit/design-system/use-primitives, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleWrapper = styled.div({
	marginLeft: token('space.200'),
});

export const AppearanceToggle = () => {
	const { appearance, setAppearance } = useContext(SpaceBlogsContext);
	return (
		<ToggleWrapper>
			<PageCardAppearanceToggle
				appearance={appearance}
				setAppearance={setAppearance}
				source="spaceBlogsScreen"
			/>
		</ToggleWrapper>
	);
};
