import { getURLBypassingResumeDraftAction } from '@confluence/content-utils';
import { transformSpaceContent } from '@confluence/page-card';
import {
	getEmojiFromPublishedPageProperties,
	getEmojiFromDraftPageProperties,
} from '@confluence/emoji-title/entry-points/transformer';
import { getTitleAndEmoji } from '@confluence/emoji-title/entry-points/helpers';

import type { BlogTree as BlogTreeQueryTypes } from './__types__/BlogTree';
import type { SpaceBlogsYearSearchResults } from './SpaceBlogsContext';

const VIRTUAL_ROOT_ID = 'VIRTUAL_ROOT_ID';
const LATEST_YEAR = new Date().getFullYear().toString();

const getYear = (date) => {
	if (!date) {
		return VIRTUAL_ROOT_ID;
	}
	const dateFragments = date.split('-');
	return dateFragments && dateFragments.length ? dateFragments[0] : VIRTUAL_ROOT_ID;
};

const resumeDraftWebUiBypass = (urlPath, searchResult, spaceKey): string => {
	const bypassUrl = getURLBypassingResumeDraftAction({
		url: urlPath || '',
		spaceKey: spaceKey || '',
		contentType: searchResult?.type,
		editorVersion: searchResult?.editorVersion?.nodes?.[0]?.value?.replace(/['"]+/g, ''),
		contentId: searchResult?.id || '',
		noContextPath: true,
	});

	return bypassUrl;
};

export const transformSearchResult = (searchResult, spaceKey, untitledDraftText) => {
	return {
		...transformSpaceContent(searchResult),
		title: getTitleAndEmoji(
			searchResult?.title || untitledDraftText,
			getEmojiFromPublishedPageProperties(searchResult),
			getEmojiFromDraftPageProperties(searchResult),
			searchResult?.status || undefined,
		),
		lastAction: {
			when: searchResult?.metadata?.lastModifiedDate,
			action: 'updated',
		},
		links: {
			webui: resumeDraftWebUiBypass(searchResult?.links?.webui, searchResult, spaceKey),
			editui: resumeDraftWebUiBypass(searchResult?.links?.editui, searchResult, spaceKey),
		},
		excerpt: searchResult.excerpt.trim(),
	};
};

export const transformData = (
	data: BlogTreeQueryTypes | undefined,
	spaceKey?: string,
	untitledDraftText?: string,
): {
	searchResultsByYear: SpaceBlogsYearSearchResults[];
	totalCount: number;
} => {
	// transform array of blogs into buckets separated by created year
	const searchResultsByYear: any = {};

	const blogs = data?.content?.nodes || [];

	blogs.forEach((node) => {
		const transformedSearchResult = transformSearchResult(node, spaceKey, untitledDraftText);
		const isDraft = transformedSearchResult.status === 'draft';
		const year = isDraft ? LATEST_YEAR : getYear(node?.history?.createdDate);

		if (!searchResultsByYear.hasOwnProperty(year)) {
			searchResultsByYear[year] = [];
		}

		searchResultsByYear[year].push(transformedSearchResult);
	});

	return {
		searchResultsByYear,
		totalCount: blogs.length || 0,
	};
};
